import React, { createContext, useRef } from "react";
import Home from "./pages/home/home";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Disclaimer from "./pages/disclaimer/disclaimer";
import Character from "./pages/character/character";
import Profile from "./pages/profile/profile";
import { useEffect } from "react";
import { useState } from "react";
import Relic from "./pages/relic/relic";
import SingleCharacter from "./pages/character/singleCharacter";
import RelicScorer from "./pages/relicScorer/relicScorer";
import CharacterRanking from "./pages/character/characterRanking";
import SingleCharacterRanking from "./pages/character/singleCharacterRanking";
import SingleCharacterRankingV2 from "./pages/character/singleCharacterRankingV2";
// import Topup_Bot from "./pages/topup_bot/topup_bot";

export const LanguageContext = React.createContext(); 

function App() {

  const messageRef = useRef();
  const [language, setLanguage] = useState("");
  const [shorten, setShorten] = useState("");

  useEffect(() => {

    function initializeLanguage()
    {
      var languageCache = localStorage.getItem("language");
      if(languageCache === "" || languageCache === null)
      {
        languageCache = "en-us";
        localStorage.setItem("language", languageCache);
        setLanguage(languageCache);
        setShorten("ENG");
      }
      else
      {
        setLanguage(languageCache);
        switch(languageCache)
        {
          case "zh-cn":
            setShorten("简中");
            break;
          case "zh-tw":
            setShorten("繁中");
            break;
          default:
            setShorten("ENG");
            break;
        }
      }
    }
    initializeLanguage();
  },[]);

  return (
    <div className="app">
      <LanguageContext.Provider value={{language, setLanguage, shorten, setShorten}}>
        <BrowserRouter>
            <Routes>
              <Route path="/*" element={<Navigate replace to="/" />} />
              <Route path="/">
                <Route index element={<Home/>} />
              </Route>
              <Route path="/:language/">
                <Route index element={<Home/>} />
              </Route>
              <Route path="/:language/relic-scorer">
                <Route index element={<RelicScorer/>} />
              </Route>
              <Route path="/:language/profile/:id">
                <Route index element={<Profile/>} />
              </Route>
              {
                /*
                            <Route path="/topup_bot/805714751">
                                <Route index element={<Topup_Bot/>}/>
                            </Route>
                */
              }

              <Route path="/:language/character">
                <Route index element={<Character/>} />
                <Route path="/:language/character/:characterName" element={<SingleCharacter/>}/>
              </Route>

              <Route path="/:language/ranking/relic">
                <Route index element={<Relic/>} />
              </Route>

              <Route path="/:language/ranking/character">
                <Route index element={<CharacterRanking/>} />
                <Route path="/:language/ranking/character/:characterName" element={<SingleCharacterRankingV2/>}/>
              </Route>


              <Route path="/:language/disclaimer">
                <Route index element={<Disclaimer/>}/>
              </Route>
            </Routes>
        </BrowserRouter>
      </LanguageContext.Provider>
    </div>
  );
}

export default App;
